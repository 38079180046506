import React, {Component} from 'react';
import {Button, Card, Col, Empty, Input, message, Table, Typography} from "antd";
import Spinner from "../base/Spinner";
import requests from "../requests";
import renderHTML from 'react-render-html';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CopyOutlined
} from '@ant-design/icons';
import ApiDoc from "./ApiDoc";
import translator from "../translator";

const {Paragraph} = Typography;

class Affiliation extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            recordsLoading: true,
            leads: [],
            registrations: [],
            sales: [],
            banners: [],
            landings: [],
            api: '',
            activeTab: 'leads',
            errorMessage: null,
            joinButtonLoading: false,
        }
    }

    getTabs = () => {
        const tabs = [
            'leads',
            'registrations',
            'sales',
            'banners',
            'landings',
            'api'
        ];
        return tabs.map((v) => {
            let tab = v.charAt(0).toUpperCase() + v.slice(1);
            if (v === 'api') tab = v.toUpperCase();
            return {
                key: v,
                tab: tab
            }
        })
    }

    getRecords = async (recordType) => {
        this.setState({
            recordsLoading: true
        });
        const r = await requests.get(`/aff/${recordType}`);
        if (r.status === 200) {
            this.setState({
                [recordType]: r.data,
                recordsLoading: false
            })
        }
        else if (r.status === 404) {
            this.setState({
                errorMessage: r.error,
                loading: false
            });
        }
        else {
            message.error(r.error);
        }
        this.setState({
            loading: false
        });
    }

    async componentDidMount(){
        const {activeTab} = this.state;
        await this.getRecords(activeTab);
    }

    join = async () => {
        this.setState({
            joinButtonLoading: true
        });
        const r = await requests.post('/aff/join');
        if (r.status === 200) {
            this.setState({
                errorMessage: null,
                leads: [],
                recordsLoading: false
            });
            message.success(r.data.message);
        } else {
            message.error(r.error);
        }
        this.setState({
            joinButtonLoading: false
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {activeTab, api} = this.state;
        if (activeTab !== 'api') {
            if (activeTab !== prevState.activeTab && this.state[activeTab].length === 0) await this.getRecords(activeTab);
        }
    }

    render(){
        const {activeTab, loading, recordsLoading, errorMessage, joinButtonLoading} = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        if (errorMessage) return <Empty style={{maxWidth: 300, margin: '0 auto'}} description={[
            <Paragraph key={'text'}>{errorMessage}</Paragraph>,
            <Button onClick={this.join} key={'button'} type={'primary'} shape={'round'} block loading={joinButtonLoading}>{translator[lang].join}</Button>
        ]}/>;
        let component;
        const columns = [
            {
                title: translator[lang].name,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: translator[lang].email,
                dataIndex: 'email',
                key: 'email',
                render: email => <a href={`mailto:${email}`}>{email}</a>
            },
            {
                title: translator[lang].phone,
                dataIndex: 'phone',
                key: 'phone',
                render: phone => <a href={`tel:${phone}`}>{phone}</a>
            },
            {
                title: translator[lang].country,
                dataIndex: 'country',
                key: 'country'
            },
            {
                title: 'IP',
                dataIndex: 'ip',
                key: 'ip'
            },
            {
                title: translator[lang].status,
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: translator[lang].registrationDate,
                dataIndex: 'registration_date',
                key: 'registration_date'
            }
        ];

        if (activeTab !== 'api') {
            if (this.state[activeTab].length === 0) {
                component = <Empty description={`No ${activeTab} found`}/>
            } else {
                switch (activeTab) {
                    case 'sales':
                        columns.splice(-1, 1);
                        columns.push(
                            {
                                title: translator[lang].amount,
                                dataIndex: 'amount',
                                key: 'amount'
                            },
                            {
                                title: translator[lang].currency,
                                dataIndex: 'currency',
                                key: 'currency'
                            },
                            {
                                title: translator[lang].date,
                                dataIndex: 'date',
                                key: 'date'
                            }
                        )
                        break;
                    case 'banners':
                        columns.length = 0;
                        columns.push(
                            {
                                title: translator[lang].banner,
                                dataIndex: 'img',
                                key: 'img',
                                render: img => renderHTML(img),
                                width: 250
                            },
                            {
                                title: translator[lang].code,
                                dataIndex: 'code',
                                key: 'code',
                                render: code => <Input.TextArea style={{width: 300, height: 200}} defaultValue={code}/>,
                                width: 250
                            },
                            {
                                title: translator[lang].action,
                                key: 'action',
                                render: data => <CopyToClipboard
                                    text={data.code}
                                    onCopy={() => message.success(translator[lang].codeCopied)}
                                >
                                    <Button type={'link'} icon={<CopyOutlined />}>{translator[lang].copyCode}</Button>
                                </CopyToClipboard>
                            }
                        )
                        break;
                    case 'landings':
                        columns.length = 0;
                        columns.push(
                            {
                                title: translator[lang].landingPage,
                                dataIndex: 'img',
                                key: 'img',
                                render: img => renderHTML(img),
                                width: 250
                            },
                            {
                                title: translator[lang].name,
                                dataIndex: 'name',
                                key: 'name',
                                width: 150
                            },
                            {
                                title: translator[lang].link,
                                dataIndex: 'link',
                                key: 'link',
                                render: link => <Input defaultValue={link} addonAfter={<CopyToClipboard
                                    text={link}
                                    onCopy={() => message.success(translator[lang].codeCopied)}
                                >
                                    <CopyOutlined />
                                </CopyToClipboard>}/>,
                                width: 250
                            }
                        )
                        break;
                }
                component = <Table scroll={{x: 300}} dataSource={this.state[activeTab]} columns={columns}/>
            }
        } else {
            component = <ApiDoc/>
        }

        return  <Card
            tabList={this.getTabs()}
            onTabChange={key => {
                this.setState({
                    activeTab: key
                });
            }}
            activeTabKey={activeTab}
        >
            {recordsLoading ? <Spinner/> : component}
        </Card>
    }

}

export default Affiliation;