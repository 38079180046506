import React, { Component } from 'react';
import './App.css';
import AuthWrapper from './components/base/AuthWrapper';
import Login from './components/forms/Login';
import User from './components/base/User';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './components/template/Layout';
import Profile from './components/forms/Profile';
import Courses from './components/app/Courses';
import requests from './components/requests';
import Spinner from './components/base/Spinner';
import Registration from './components/forms/Registration';
import Course from './components/app/Course';
import Info from './components/app/Info';
import Session from './components/base/Session';
import Payment from './components/app/Payment';
import Payments from './components/app/Payments';
import Affiliation from './components/app/Affiliation';
import Wire from './components/app/Wire';
import RegistrationAgreementModal from './components/modals/RegistrationAgreement';

class App extends Component {
  constructor(props, context) {
    const user = new User();
    super(props, context);
    this.state = {
      authorized: user.get(),
      title: 'My Courses',
      brand: {},
      loading: true,
      registration: false,
      utm_source: false,
      lang: 'en',
      user,
    };
  }

  async componentDidMount() {
    console.log('By @qper228');
    const r = await requests.get('/brand/info');
    if (r.status === 200) {
      const qs = window.location.search;
      if (qs) {
        const params = {};
        qs.substr(1)
          .split('&')
          .map((v) => {
            const values = v.split('=', 2);
            params[values[0]] = values[1];
            return null;
          });
        this.setState(params);
      }
      this.setState({
        registration: qs.includes('registration'),
        brand: r.data,
        loading: false,
      });
    }
  }

  setTitle = (title) => {
    this.setState({
      title: title,
    });
  };

  authorize = () => {
    this.setState({
      authorized: true,
      user: new User(),
    });
    if (localStorage.getItem('loginRedirect')) {
      window.location.href = localStorage.getItem('loginRedirect');
      localStorage.removeItem('loginRedirect');
    }
  };

  render() {
    const {
      authorized,
      loading,
      brand,
      registration,
      utm_source,
      title,
      lang,
      user,
    } = this.state;
    if (loading) return <Spinner full={true} />;
    if (!authorized && window.location.pathname !== '/session')
      return (
        <AuthWrapper
          lang={lang}
          brand={brand}
          form={
            registration ? (
              <Registration
                lang={lang}
                source={utm_source}
                brand={brand}
                authorize={this.authorize}
              />
            ) : (
              <Login lang={lang} brand={brand} />
            )
          }
          authorize={this.authorize}
        />
      );

    const {
      user: { pop },
    } = user.get();

    if (pop === null || pop === '')
      return (
        <RegistrationAgreementModal
          user={user.get().user}
          authorize={this.authorize}
          brand={brand}
          setUserKey={(k, v) => user.set(k, v)}
        />
      );

    return (
      <Router basename="/">
        <Switch>
          <Layout
            brand={brand}
            title={title}
            setTitle={this.setTitle}
            lang={lang}
          >
            <Route
              exact={true}
              path="/"
              render={() => (
                <Courses
                  lang={lang}
                  path={'my'}
                  key={'my'}
                  user={authorized.user}
                  brand={brand}
                />
              )}
            />
            <Route
              exact={true}
              path="/buy"
              render={() => (
                <Courses
                  path={'list'}
                  key={'list'}
                  lang={lang}
                  user={authorized.user}
                  brand={brand}
                />
              )}
            />
            <Route
              exact={true}
              path="/payments"
              render={() => <Payments lang={lang} />}
            />
            <Route
              exact={true}
              path="/affiliation"
              render={() => <Affiliation lang={lang} />}
            />
            <Route
              exact={true}
              path="/profile"
              render={() => <Profile lang={lang} />}
            />
            <Route
              exact={true}
              path="/course/:slug"
              render={() => <Course lang={lang} setTitle={this.setTitle} />}
            />
            <Route
              exact={true}
              path={'/info/:id'}
              render={() => <Info lang={lang} />}
            />
            <Route exact={true} path={'/session'} render={() => <Session />} />
            <Route
              exact={true}
              path={'/payment/:id'}
              render={() => <Payment lang={lang} />}
            />
            <Route
              exact={true}
              path={'/wire/:id'}
              render={() => <Wire lang={lang} />}
            />
          </Layout>
        </Switch>
      </Router>
    );
  }
}

export default App;
