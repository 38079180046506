import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import requests from "../requests";
import Spinner from "../base/Spinner";
import {Button, Empty, Menu, Typography, Drawer, message} from "antd";
import styled from 'styled-components';
import Lesson from "./Lesson";
import {
    CheckCircleOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import translator from "../translator";


const {Paragraph, Title} = Typography;

const Wrap = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 15px;
    
    @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

class Course extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            errorMessage: null,
            course: null,
            lessons: [],
            completed: [],
            activeLesson: 0,
            showDrawer: false
        }
    }

    async componentDidMount() {
        const {slug} = this.props.match.params;
        const {setTitle, lang} = this.props;
        let r = await requests.get(`/courses/view?slug=${slug}&lang=${lang}`)
        switch (r.status) {
            case 200:
                setTitle(r.data.course.title);
                this.setState({
                    course: r.data.course,
                    lessons: r.data.lessons,
                    completed: r.data.completed
                })
                break;
            case 403:
                // const button = <Button type={'primary'} onClick={() => this.props.history.push('/buy')} block shape={'round'}>Buy course</Button>;
                // const message = [r.error, button]
                // this.setState({ errorMessage: message })
                const {id} = r.data;
                r = await requests.post('/finance/init_payment', {
                    course: id
                });
                if (r.status === 200) {
                    this.props.history.push(r.data.push);
                } else {
                    this.setState({
                        buyLoading: false,
                    });
                    message.error(r.error);
                }
                break;
            case 404:
                this.setState({ errorMessage: r.error })
                break;
            default:
                return null;
        }
        this.setState({
            loading: false
        });
    }

    complete = async () => {
        const {course, activeLesson, lessons} = this.state;
        const r = await requests.post('/courses/complete_lesson', {
            course: course.id,
            lesson: lessons[activeLesson].id
        });
        if (r.status === 200) {
            this.setState({
                completed: r.data.completed
            })
        }
    }

    render() {
        const {loading, errorMessage, course, lessons, activeLesson, completed, showDrawer} = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        if (errorMessage) return <Empty style={{maxWidth: 300, margin: '0 auto'}} description={errorMessage} />;
        return <Wrap>
            <div>
                <Title level={4}>{course.title} <Button onClick={() => this.setState({
                    showDrawer: true
                })} size={'small'} id={'show-list-mobile'} type={'primary'} icon={<UnorderedListOutlined />}/></Title>
                <Paragraph>{course.description}</Paragraph>
                <Lesson
                    key={activeLesson}
                    lesson={lessons[activeLesson]}
                    complete={this.complete}
                    completed={completed.includes(lessons[activeLesson].id)}
                    lang={lang}
                />
            </div>
            <div>
                <Menu
                    id={'course-videos'}
                    onClick={e => this.setState({activeLesson: parseInt(e.key)})}
                    defaultSelectedKeys={[String(activeLesson)]}
                >
                    {lessons.map((v, i) => {
                        return <Menu.Item key={i} icon={completed.includes(v.id) ? <CheckCircleOutlined style={{color: 'green'}} /> : false}>
                            {v.video.title}
                        </Menu.Item>
                    })}
                </Menu>
                <Drawer
                    title={translator[lang].courseVideos}
                    placement={'right'}
                    closable={true}
                    onClose={() => this.setState({showDrawer: false})}
                    visible={showDrawer}
                    key={'drawer'}
                >
                    <Menu
                        onClick={e => this.setState({
                            activeLesson: parseInt(e.key),
                            showDrawer: false
                        })}
                        defaultSelectedKeys={[String(activeLesson)]}
                    >
                        {lessons.map((v, i) => {
                            return <Menu.Item key={i} icon={completed.includes(v.id) ? <CheckCircleOutlined style={{color: 'green'}} /> : false}>
                                {v.video.title}
                            </Menu.Item>
                        })}
                    </Menu>
                </Drawer>
            </div>
        </Wrap>
    }
}

export default withRouter(Course);