import React, { useState, useRef } from 'react';
import { Modal, Form, Button, message, Input } from 'antd';
import requests from '../requests';
import moment from 'moment';

const PurchaseAgreementsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const handleSubmit = async ({ id: passport }) => {
    setLoading(true);
    const id = props.purchase.purchase_id;
    const canvas = canvasRef.current;
    const signature = canvas.toDataURL('image/png');

    const res = await requests.post('/courses/purchase_agree', {
      purchase: id,
      signature,
      passport,
    });
    if (res.status === 200) {
      message.success(res.data.message);
      props.agree(id);
    } else {
      console.log(res);
      message.error(res.error);
    }
    setLoading(false);
  };

  // Common function to get coordinates from mouse or touch events
  const getCoordinates = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    return { x, y };
  };

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { x, y } = getCoordinates(e);

    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { x, y } = getCoordinates(e);

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  // Add touch event handlers
  const touchStart = (e) => {
    e.preventDefault();
    startDrawing(e.touches[0]);
  };

  const touchMove = (e) => {
    e.preventDefault();
    draw(e.touches[0]);
  };

  const touchEnd = (e) => {
    e.preventDefault();
    stopDrawing();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const validateCanvas = (_, value) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const canvasData = context.getImageData(
      0,
      0,
      canvas.width,
      canvas.height,
    ).data;

    for (let i = 0; i < canvasData.length; i += 4) {
      if (canvasData[i + 3] !== 0) {
        return Promise.resolve();
      }
    }

    return Promise.reject(new Error('Please provide a signature'));
  };

  const { user, brand, purchase } = props;
  const name = `${user.first_name} ${user.last_name}`;

  return (
    <div>
      <Modal
        title="DECLARATION OF PURCHASE OF ONLINE ACADEMY COURSES"
        visible={true}
        closable={false}
        footer={null}
        confirmLoading={loading}
      >
        <Form onFinish={handleSubmit}>
          <p>
            I the undersigned {name} holder of a Passport/ID:
            <Form.Item
              name="id"
              rules={[
                { required: true, message: 'Please enter the Passport/ID' },
              ]}
            >
              <Input name="id" />
            </Form.Item>
            confirm and agree with the terms of this Declaration of Purchase,{' '}
            {brand.name} Terms and Conditions, published on the website{' '}
            {brand.website} (“{brand.name}”)
          </p>
          <p>
            Signing this form I confirm the following:
            <ul>
              <li>
                I am obligated to ask refund from the company in any case I have
                any issues regarding the service - during 48 hours after
                watching and learning the relevant course
              </li>
              <li>
                I have made a purchase of a course named {purchase.title}
                on {purchase.purchase_date}
              </li>
            </ul>
          </p>
          <br />
          <p>Name: {name}</p>
          <p>Date: {moment().format('YYYY-MM-DD')}</p>
          <br />
          <p>Signature:</p>
          <Form.Item
            name="signature"
            label="Signature"
            rules={[
              {
                validator: validateCanvas,
              },
            ]}
          >
            <canvas
              ref={canvasRef}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onTouchStart={touchStart}
              onTouchMove={touchMove}
              onTouchEnd={touchEnd}
              width="450"
              height="200"
              style={{ border: '1px solid #000', width: '100%', touchAction: 'none' }}
            ></canvas>
          </Form.Item>

          <br />
          <Button type="secondary" onClick={clearCanvas}>
            Clear Signature
          </Button>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Document
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PurchaseAgreementsModal;
