import React, {Component} from 'react';
import { Menu } from 'antd';
import {
    DollarCircleOutlined,
    DesktopOutlined,
    UserOutlined,
    LogoutOutlined,
    WalletOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';
import {withRouter} from "react-router-dom";
import translator from "../translator";


class Navigation extends Component {

    render(){
        const {path, push, logout, lang} = this.props;
        const width = window.innerWidth;
        const style = {
            // minHeight: 'calc(100vh - 64px)',
            minHeight: '100vh',
            position: 'fixed',
            width: '250px',
            // top: 76,
            [lang === 'ar' ? 'right' : 'left']: 0,
            textAlign: lang === 'ar' ? 'right' : 'left'
        }
        let collapsed = false;
        if (width < 768) {
            // style.minHeight = 'calc(100vh - 72px)';
            style.width = '80px';
            collapsed = true;
        }
        return (
            <Menu
                onClick={e => push(e.key)}
                defaultSelectedKeys={[path]}
                mode="inline"
                theme="dark"
                inlineCollapsed={collapsed}
                style={style}
            >
                <Menu.Item key="/" icon={<DesktopOutlined />}>
                    <span>{translator[lang].myCourses}</span>
                </Menu.Item>
                <Menu.Item key="/buy" icon={<DollarCircleOutlined />}>
                    <span>{translator[lang].buyCourses}</span>
                </Menu.Item>
                <Menu.Item key="/payments" icon={<WalletOutlined />}>
                    <span>{translator[lang].payments}</span>
                </Menu.Item>
                {/*<Menu.Item key="/affiliation" icon={<UsergroupAddOutlined />}>*/}
                {/*    <span>{translator[lang].affiliation}</span>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="/profile" icon={<UserOutlined />}>
                    <span>{translator[lang].profile}</span>
                </Menu.Item>
                <Menu.Item key="/logout" icon={<LogoutOutlined />} onClick={logout}>
                    <span>{translator[lang].logOut}</span>
                </Menu.Item>
            </Menu>
        )
    }

}

export default withRouter(Navigation);