import React, {Component} from 'react';
import {Button, Checkbox, Form, Input, message, Modal, Typography} from 'antd'
import requests from "../requests";
import User from "../base/User";
import Spinner from "../base/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import translator from "../translator";
import PhoneConfirmation from "../modals/PhoneConfirmation";


const {Paragraph, Title} = Typography;

class Registration extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      buttonLoad: false,
      phoneCode: null,
      loading: true,
      captcha: false,
      confirmationModal: false,
      phone: null
    }
  }

  sendCode = async () => {
    const res = await requests.post('/authorization/send_verification_code');
    if (res.status === 200) {
      this.setState({
        codeSent: true,
        confirmationModal: true
      })
    } else {
      message.error(res.error);
      this.props.authorize();
    }
  }

  register = async values => {
    const {captcha} = this.state;
    const {source, lang} = this.props;
    const self = this;
    if (!captcha) {
      message.error(translator[lang].notRobot);
      return null;
    }

    this.setState({
      buttonLoad: true,
      phone: values.phone
    });
    delete values.tc;
    delete values.pp;
    values.source = source;
    values.lang = lang;
    values.captcha = captcha;
    const res = await requests.post('/authorization/register', values);
    if (res.status === 200) {
      Modal.info({
        title: '',
        content: (
            <Paragraph dir={lang === 'ar' ? 'rtl' : 'ltr'}>{translator[lang].disclaimer}</Paragraph>
        ),
        async onOk(){
          const user = new User();
          await user.authorize(res.data.data);
          await self.sendCode();
        }
      })
      message.success(res.data.message);
    } else {
      message.error(res.error);
      this.setState({
        buttonLoad: false
      });
    }
  };

  async componentDidMount() {
    const res = await requests.get('/utils/country_code')
    this.setState({
      phoneCode: res.data.dial_code,
      loading: false
    })
  }

  handleCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  }

  render(){
    const {switchForm, brand, lang, authorize} = this.props;
    const {buttonLoad, phoneCode, loading, confirmationModal, phone} = this.state;
    if (loading) return <Spinner/>;
    return (
      <div>
        <Title level={3}>{translator[lang].hello}</Title>
        <Title level={4}>{translator[lang].applyForAccount}</Title>
        <Form
          name="basic"
          initialValues={{ phone: phoneCode}}
          onFinish={this.register}
        >
          <Form.Item
            name="first_name"
            rules={[{ required: true, message: translator[lang].pleaseFirstName}]}
          >
            <Input placeholder={translator[lang].firstName} />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[{ required: true, message: translator[lang].pleaseLastName }]}
          >
            <Input placeholder={translator[lang].lastName} />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: translator[lang].pleaseEmail }]}
          >
            <Input placeholder={translator[lang].email} />
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[{ required: true, message: translator[lang].pleasePhone }]}
          >
            <Input placeholder={translator[lang].phone} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: translator[lang].pleasePassword }]}
          >
            <Input.Password placeholder={translator[lang].password} />
          </Form.Item>

          <Form.Item
              name="promo_code"
          >
            <Input placeholder={translator[lang].promoCode} />
          </Form.Item>

          <Form.Item
              name="tc"
              valuePropName="checked"
              rules={[{ required: true, message: translator[lang].pleaseTerms }]}
              style={{marginBottom: 0}}
          >
            <Checkbox>{translator[lang].iAccept} <a rel={'noopener noreferrer'} target={'_blank'} href={brand.tc_link}>{translator[lang].tAndC}</a></Checkbox>
          </Form.Item>
          <Form.Item
              name="pp"
              valuePropName="checked"
              rules={[{ required: true, message: translator[lang].pleasePolicy }]}
          >
            <Checkbox>{translator[lang].iAccept} <a rel={'noopener noreferrer'} target={'_blank'} href={brand.pp_link}>{translator[lang].pp}</a></Checkbox>
          </Form.Item>
          <Form.Item style={{textAlign: 'center'}}>
            <ReCAPTCHA
                sitekey={brand.google_captcha_key}
                onChange={this.handleCaptcha}
                size="normal"
                className="captcha"
                badge={'inline'}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={buttonLoad} danger>
              {translator[lang].registerNow}
            </Button>
          </Form.Item>
        </Form>
        <div className="form-links" style={{textAlign: 'right'}}>
          <Paragraph>{translator[lang].alreadyHave} <button onClick={() => switchForm('login')} type="link">{translator[lang].logIn}</button></Paragraph>
        </div>
        <PhoneConfirmation
            show={confirmationModal}
            close={() => {
              this.setState({
                confirmationModal: false
              })
            }}
            phone={phone}
            update={authorize}
        />
      </div>

    )
  }

}

export default Registration;

