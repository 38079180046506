import React, {Component} from 'react';
import { PageHeader, Button, Typography } from 'antd';
import {withRouter} from "react-router-dom";
import User from "../base/User";


const {Text} = Typography;

class Header extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            buttonLoad: false,
        }
    }

    render(){
        const user = new User();
        const {title, brand, logout, url} = this.props;
        const {buttonLoad} = this.state;
        return (
            <PageHeader
                ghost={false}
                onBack={false}
                title={<a href={url}><img src={brand} width={200} alt={'logo'}/></a>}
                subTitle={title}
                style={{
                    position: 'fixed',
                    width: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
                extra={window.innerWidth < 768 ? false : [
                    <Text key={1}>Welcome, <strong>{user.get('user').first_name}</strong></Text>,
                    <Button key={2} loading={buttonLoad} onClick={logout} type="default" shape={'round'}>
                        Log Out
                    </Button>,
                ]}
            />
        )
    }

}

export default withRouter(Header);