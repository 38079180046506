import React, {Component} from 'react';
import {InputNumber, message, Modal, Typography} from "antd";
import requests from "../requests";


const {Text} = Typography;

class PhoneConfirmation extends Component {

    constructor (props, context) {
        super (props, context);
        this.state = {
            code: '',
            loading: false
        }
    }

    handleChange = (e) => {
        this.setState({
            code: e
        })
    }

    confirm = async () => {
        this.setState({
            loading: true
        })
        const {code} = this.state;
        const {close, update} = this.props;
        const res = await requests.post('/authorization/phone', {
            code: code
        });
        if (res.status === 200) {
            this.setState({
                loading: false
            });
            message.success(res.data.message);
            update(res.data.user);
            close();
        } else {
            console.log(res);
            this.setState({
                loading: false
            });
            message.error(res.error);
        }
    }

    render() {
        const {show, close, phone} = this.props;
        const {code, loading} = this.state;
        return (
            <Modal
                title="Phone confirmation"
                visible={show}
                onOk={this.confirm}
                onCancel={() => close()}
                confirmLoading={loading}
                width={400}
            >
                <Text>We sent confirmation code to <strong>{phone}</strong>. Please, put code below:</Text>
                <InputNumber style={{width: '100%'}} placeholder="Confirmation Code" onChange={this.handleChange} value={code}/>
            </Modal>
        );
    }

}

export default PhoneConfirmation;