import styled from 'styled-components';

export default styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: url(${props => props.img}) no-repeat center;
  margin: 20px auto;
  background-size: 90%;
  background-color: ${props => props.backgroundColor};
  border-radius: 20px;
`;