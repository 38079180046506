import React, {Component} from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';


const Wrapper = styled.div`
  padding: 25px;
  text-align: center;
  ${props => props.full && 'min-height: 100vh;'}
`

class Spinner extends Component {

  render(){
    const {full, img} = this.props;
    const style = {
      fontSize: 36
    };
    if (full) {
      style.transform = 'translate: (-50%, -50%)';
      style.top = '50%';
      style.left = '50%';
      style.position = 'absolute';
    }
    return <Wrapper full={full}>{img ? img : <LoadingOutlined style={style}/>}</Wrapper>;
  }

}

export default Spinner;

