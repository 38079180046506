import React, {Component} from 'react';
import {Form, Input, InputNumber, Button, Select, Row, Col, Typography, message, DatePicker} from 'antd';
import Spinner from "../base/Spinner";
import requests from "../requests";
import PhoneConfirmation from "../modals/PhoneConfirmation";
import moment from "moment";
import translator from "../translator";


const {Title} = Typography;
const { Option } = Select;
const phoneCodeDelay = 300;
const emailDelay = 300;

class Profile extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            profileData: {},
            loading: true,
            buttonLoading: false,
            countryList: [],
            phoneConfirmationText: [
                <span key={1}>{translator[props.lang].notConfirmed}</span>,
                <Button size={'small'} type={'link'} key={2} onClick={this.sendCode}>{translator[props.lang].sendCode}</Button>
            ],
            codeSent: false,
            secondsToGo: phoneCodeDelay,
            confirmationModal: false,
            emailConfirmationText: [
                <span key={1}>{translator[props.lang].notConfirmed}</span>,
                <Button size={'small'} type={'link'} key={2} onClick={this.sendEmail}>{translator[props.lang].sendEmail}</Button>
            ],
            emailSent: false,
            emailSecondsToGo: emailDelay
        }
    }

    onSubmit = async values => {
        this.setState({
            buttonLoading: true
        });
        values.birth_date = moment(values.birth_date).format('YYYY-MM-DD');
        const res = await requests.post('/authorization/update_profile', values);
        if (res.status === 200) {
            message.success(res.data.message);
            this.setState({
                profileData: res.data.user
            })
        } else {
            message.error(res.error);
        }
        this.setState({
            buttonLoading: false
        });
    }

    countDown = async () => {
        const {secondsToGo, codeSent} = this.state;
        if (secondsToGo > 0 && codeSent) {
            this.setState({
                secondsToGo: secondsToGo - 1
            });
            localStorage.setItem('phoneCodeCountDown', secondsToGo);
        } else {
            clearInterval(this.interval);
            localStorage.removeItem('phoneCodeCountDown');
            this.setState({
                codeSent: false,
                secondsToGo: phoneCodeDelay
            });

        }
    }

    emailCountDown = async () => {

        const {emailSecondsToGo, emailSent} = this.state;
        if (emailSecondsToGo > 0 && emailSent) {
            this.setState({
                emailSecondsToGo: emailSecondsToGo - 1
            });
            localStorage.setItem('emailCountDown', emailSecondsToGo);
        } else {
            clearInterval(this.emailInterval);
            localStorage.removeItem('emailCountDown');
            this.setState({
                emailSent: false,
                emailSecondsToGo: emailDelay
            })
        }
    }

    async componentDidMount() {
        const {lang} = this.props;
        if (localStorage.getItem('emailCountDown')) {
            this.interval = setInterval(this.emailCountDown, 1000);
            this.setState({
                emailSent: true,
                emailSecondsToGo: localStorage.getItem('emailCountDown')
            })
        }

        if (localStorage.getItem('phoneCodeCountDown')) {
            this.interval = setInterval(this.countDown, 1000);
            this.setState({
                codeSent: true,
                secondsToGo: localStorage.getItem('phoneCodeCountDown')
            })
        }

        let res = await requests.get('/utils/country_list');
        if (res.status === 200) {
            this.setState({
                countryList: res.data
            })
        }

        res = await requests.get('/authorization/me')
        if (res.status === 200) {
            this.setState({
                profileData: res.data,
                loading: false
            })
        } else {
            message.error(translator[lang].unexpectedError);
        }
    }

    sendCode = async () => {
        const res = await requests.post('/authorization/send_verification_code');
        if (res.status === 200) {
            this.interval = setInterval(this.countDown, 1000);
            this.setState({
                codeSent: true,
                confirmationModal: true
            })
        } else {
            message.error(res.error);
        }
    }

    sendEmail = async () => {
        const res = await requests.post('/authorization/send_email_confirmation');
        if (res.status === 200) {
            this.emailInterval = setInterval(this.emailCountDown, 1000);
            this.setState({
                emailSent: true,
            })
        } else {
            message.error(res.error);
        }
    }

    updateProfile = (data) => {
        clearInterval(this.interval);
        localStorage.removeItem('phoneCodeCountDown');
        this.setState({
            profileData: data,
            codeSent: false
        })
    }

    render () {
        const {
            loading,
            countryList,
            profileData,
            phoneConfirmationText,
            codeSent,
            secondsToGo,
            confirmationModal,
            emailSent,
            emailSecondsToGo,
            emailConfirmationText,
            buttonLoading
        } = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        return (
            <Form
                onFinish={this.onSubmit}
                layout={'vertical'}
                initialValues={{
                    first_name: profileData.first_name,
                    last_name: profileData.last_name,
                    email: profileData.email,
                    phone: profileData.phone,
                    country: profileData.country,
                    state: profileData.state,
                    city: profileData.city,
                    address: profileData.address,
                    zip: profileData.zip,
                    birth_date: profileData.birth_date ? moment(profileData.birth_date) : null
                }}
            >
                <Row gutter={16}>
                    <Col md={6} xs={24}>
                        <Title level={4}>{translator[lang].personalDetails}</Title>
                        <Form.Item name={'first_name'} label={translator[lang].firstName} rules={[{ required: true }]}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name={'last_name'} label={translator[lang].lastName} rules={[{ required: true }]}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item
                            name={'email'}
                            label={translator[lang].email}
                            rules={[{ type: 'email', required: true }]}
                            extra={emailSent ? `Resend in ${emailSecondsToGo} seconds` : profileData.email_confirmed ? translator[lang].emailConfirmed : emailConfirmationText}
                        >
                            <Input autoComplete="new-password" disabled={profileData.email_confirmed} />
                        </Form.Item>
                        <Form.Item
                            name={'phone'}
                            label={translator[lang].phone}
                            rules={[{ required: true }]}
                            extra={codeSent ? `Resend in ${secondsToGo} seconds` : profileData.phone_confirmed ? translator[lang].phoneConfirmed : phoneConfirmationText}
                        >
                            <InputNumber
                                autoComplete="new-password"
                                formatter={value => `+ ${value}`}
                                // parser={value => value.replace('+', '')}
                                style={{width: '100%'}}
                                disabled={profileData.phone_confirmed}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Title level={4}>{translator[lang].billingDetails}</Title>
                        <Form.Item name={'country'} label={translator[lang].country} rules={[{ required: true }]}>
                            <Select
                                showSearch
                                placeholder={translator[lang].selectCountry}
                                autoComplete="new-password"
                            >
                                {countryList.map((v, i) => {
                                    return <Option key={i} value={v.iso}>{v.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'state'} label={translator[lang].state}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name={'city'} label={translator[lang].city} rules={[{ required: true }]}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name={'address'} label={translator[lang].address} rules={[{ required: true }]}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name={'zip'} label={translator[lang].zip} rules={[{ required: true }]}>
                            <Input autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name={'birth_date'} label={translator[lang].dob} rules={[{ required: true }]}>
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Title level={4}>{translator[lang].changePassword}</Title>
                        <Form.Item
                            label={translator[lang].currentPassword}
                            name="current_password"
                        >
                            <Input.Password autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item
                            label={translator[lang].newPassword}
                            name="password"
                        >
                            <Input.Password autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{textAlign: 'center'}}>
                    <Button shape={'round'} block={window.innerWidth < 768} type="primary" htmlType="submit" loading={buttonLoading}>
                        {translator[lang].save}
                    </Button>
                </div>
                <PhoneConfirmation
                    show={confirmationModal}
                    close={() => {
                        this.setState({
                            confirmationModal: false
                        })
                    }}
                    phone={profileData.phone}
                    update={this.updateProfile}
                />
            </Form>
        )
    }
}

export default Profile;

