import React, {Component} from 'react';
import { Input, Modal, message } from 'antd'
import requests from "../requests";


class PasswordRecovery extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      email: ''
    }
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  recover = async () => {
    const {email} = this.state;
    const {close} = this.props;
    const res = await requests.post('/authorization/reset_password', {
      email: email
    });
    if (res.status === 200) {
      message.success(res.data.message);
      close();
    } else {
      message.error(res.error);
    }
  }

  render() {
    const {show, close} = this.props;
    const {email} = this.state;
    return (
      <Modal
        title="Password recovery"
        visible={show}
        onOk={this.recover}
        onCancel={() => close()}
      >
        <Input placeholder="Enter your email" onChange={this.handleChange} value={email}/>
      </Modal>
    );
  }

}

export default PasswordRecovery;