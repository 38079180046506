import React, {Component} from 'react';
import User from "./User";


class Session extends Component {

    componentDidMount() {
        const user = new User();
        window.parent.postMessage(user.get(), '*');
    }

    render(){
        return '';
    }

}

export default Session;