import axios from 'axios';
import Constants from "./base/Constants";
import User from "./base/User";
import brand from "../brand";

class requests {

    static headers = () => {
        const headers = {
            Brand: brand
        };
        const user = new User();
        if (user.get('token')) headers['Auth'] = user.get('token').value;
        return headers;
    }

    static get = (path, params= {}) => {
        return axios({
            method: 'get',
            url: `${Constants.host}${path}`,
            headers: requests.headers(),
            data: params
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                status: error.response.status,
                data: error.response.data
            };
        });
    }

    static post = (path, params = {}) => {
        return axios({
            method: 'post',
            url: `${Constants.host}${path}`,
            headers: requests.headers(),
            data: params
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                status: error.response.status,
                data: error.response.data
            };
        });
    }

}

export default requests;