import React, {Component} from 'react';
import Spinner from "../base/Spinner";
import requests from "../requests";
import {Button, Card, Empty, message, Typography} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import {CopyToClipboard} from "react-copy-to-clipboard";
import styled from 'styled-components';

const {Paragraph, Title, Text} = Typography;
const Data = styled.div`
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`;

class ApiDoc extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            apiKey: null,
            loading: true,
            errorMessage: null,
            buttonLoading: false
        }
    }

    async componentDidMount() {
        const r = await requests.get(`/aff/api`);
        if (r.status === 200) {
            this.setState({
                apiKey: r.data.api_key,
            })
        }
        else if (r.status === 404) {
            this.setState({
                errorMessage: r.error,
            });
        }
        else {
            message.error(r.error);
        }
        this.setState({
            loading: false
        })
    }

    createApiKey = async () => {
        this.setState({
            buttonLoading: true
        })
        const r = await requests.post('/aff/create_api_key');
        if (r.status === 200) {
            this.setState({
                apiKey: r.data.api_key,
                errorMessage: null
            })
        }
        else {
            message.error(r.error);
        }
        this.setState({
            buttonLoading: false
        })
    }

    render() {
        const {loading, apiKey, errorMessage, buttonLoading} = this.state;
        if (loading) return <Spinner/>;
        if (errorMessage) return <Empty style={{maxWidth: 300, margin: '0 auto'}} description={[
            <Paragraph key={'text'}>{errorMessage}</Paragraph>,
            <Button onClick={this.createApiKey} key={'button'} type={'primary'} shape={'round'} block loading={buttonLoading}>Create API key</Button>
        ]}/>;
        return <Card>
            <Title level={3}>Your API key: <Text copyable>{apiKey}</Text></Title>
            <Paragraph strong>Usage:</Paragraph>
            <Paragraph>Provide your requests with headers:</Paragraph>
            <code>
                Affiliate: {apiKey} <br/>
                Content-Type: application/json
            </code>
            <Title level={4}>1. Create new lead:</Title>
            <Paragraph strong>Send POST request:</Paragraph>
            <Paragraph copyable={{text: 'https://learn.gcg.technology/aff/lead'}}>
                URL: <code>https://learn.gcg.technology/aff/lead</code>
            </Paragraph>
            <Paragraph>
                Body: <br/>
                <Data>
                    &#123; <br/>
                    &nbsp;&nbsp;&nbsp;"<span style={{color: 'green'}}>name</span>": "John Doe", <br/>
                    &nbsp;&nbsp;&nbsp;"<span style={{color: 'green'}}>email</span>": "johndoe@example.com", <br/>
                    &nbsp;&nbsp;&nbsp;"<span style={{color: 'green'}}>phone</span>": "+44123465789", <br/>
                    &nbsp;&nbsp;&nbsp;"<span>country</span>": "GB", <br/>
                    &nbsp;&nbsp;&nbsp;"<span>city</span>": "London", <br/>
                    &nbsp;&nbsp;&nbsp;"<span>state</span>": "Greater London City of London", <br/>
                    &nbsp;&nbsp;&nbsp;"<span>ip</span>": "18.123.45.67", <br/>
                    &#125;
                </Data>
            </Paragraph>
            <Text>With green color marked mandatory parameters</Text>
            <Title level={4}>2. Get all leads:</Title>
            <Paragraph strong>Send GET request:</Paragraph>
            <Paragraph copyable={{text: 'https://learn.gcg.technology/aff/all_leads'}}>
                URL: <code>https://learn.gcg.technology/aff/all_leads</code>
            </Paragraph>
        </Card>
    }

}

export default ApiDoc;