import React, {Component} from 'react';
import requests from "../requests";
import {Card, Table, Tag, Tooltip, Typography} from "antd";
import Spinner from "../base/Spinner";
import {withRouter} from "react-router-dom";
import translator from "../translator";

const {Text} = Typography;

class Payments extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            payments: [],
            loading: true
        }
    }

    async componentDidMount() {
        const r = await requests.get('/finance/payments');
        if (r.status === 200) {
            this.setState({
                payments: r.data,
                loading: false
            })
        }
    }

    render() {
        const {loading, payments} = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        const columns = [
            {
                title: '#',
                dataIndex: 'no',
                key: 'no'
            },
            {
                title: translator[lang].course,
                dataIndex: 'course',
                key: 'course',
                render: course => course.title
            },
            {
                title: translator[lang].created,
                dataIndex: 'created',
                key: 'created'
            },
            {
                title: translator[lang].amount,
                dataIndex: 'amount',
                key: 'amount'
            },
            {
                title: translator[lang].currency,
                dataIndex: 'currency',
                key: 'currency'
            },
            {
                title: translator[lang].status,
                key: 'status',
                render: data => {
                    switch (data.status) {
                        case 'new':
                            return <Tag color={'#108ee9'}>{translator[lang].new}</Tag>;
                        case 'pending':
                            return <Tag color={'#2db7f5'}>{translator[lang].pending}</Tag>;
                        case 'completed':
                            return <Tag color={'#87d068'}>{translator[lang].completed}</Tag>;
                        case 'failed':
                            return <Tooltip placement="bottom" title={data.decline_reason}>
                                <Tag color={'#f50'}>{translator[lang].failed} *</Tag>
                            </Tooltip>;
                    }
                }
            },
        ];
        return <Card title={translator[lang].payments}>
            <Table scroll={{x: 300}} dataSource={payments} columns={columns} onRow={(record) => {
                return {
                    onClick: e => {
                        switch (record.status) {
                            case 'new':
                                this.props.history.push(`/payment/${record.id}`)
                                break;
                            case 'pending':
                                window.parent.location.href = record.redirect_url;
                                break;
                            case 'completed':
                                this.props.history.push(`/course/${record.course.path}`)
                                break;
                            default:
                                return null;
                        }
                    }
                };
            }}/>
            <Text>* {translator[lang].hoverStatus}</Text>
        </Card>
    }

}

export default withRouter(Payments);