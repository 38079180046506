import React, {Component} from 'react';
import {Button, Card, Drawer, Tag, Typography, message} from "antd";
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import {withRouter} from "react-router-dom";
import requests from "../requests";
import translator from "../translator";


const {Meta} = Card;
const {Paragraph} = Typography;

const PriceWrapper = styled.div`
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
`;

const Poster = styled.div`
    background: url("${props => props.src}") no-repeat center;
    background-size: cover;
    width: 100%;
    padding-top: 100%;
    //height: 250px;
    margin: 0 auto;
`;


class SingleCourse extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showDrawer: false,
            buyLoading: false
        }
    }

    buy = async () => {
        const {data} = this.props;
        this.setState({
            buyLoading: true,
            showDrawer: false
        });
        const r = await requests.post('/finance/init_payment', {
            course: data.id
        });
        if (r.status === 200) {
            this.props.history.push(r.data.push);
        } else {
            this.setState({
                buyLoading: false,
            });
            message.error(r.error);
        }
    }

    render () {
        const {data, lang} = this.props;
        const {showDrawer, buyLoading} = this.state;
        return (
            <div>
                <Card
                    hoverable
                    cover={<Poster src={data.poster}/>}
                    onClick={data.purchased ? () => this.props.history.push(`/course/${data.path}`) : () => {
                        this.setState({
                        showDrawer: true
                    })}}
                >
                    <Meta style={{minHeight: 100}} title={data.title} description={data.short_description} />
                    <PriceWrapper>
                        <Paragraph style={{margin: '15px 0'}} strong>{!data.purchased ? `${data.price} EUR` : <Tag color={'green'}>{translator[lang].purchased}</Tag>}</Paragraph>
                        <Button
                            type={data.purchased ? 'default' : 'primary'}
                            danger={!data.purchased}
                            shape={'round'}
                            block
                            loading={buyLoading}
                        >{data.purchased ? translator[lang].viewCourse : translator[lang].buy}</Button>
                    </PriceWrapper>
                </Card>
                <Drawer
                    title={data.title}
                    placement={'right'}
                    closable={true}
                    onClose={() => this.setState({showDrawer: false})}
                    visible={showDrawer}
                    width={300}
                    style={{
                        maxWidth: '100%'
                    }}
                >
                    <Paragraph>{data.description}</Paragraph>
                    <Poster src={data.poster}/>
                    {/*<ReactPlayer style={{marginBottom: 20}} width={'100%'} height={240} url={data.intro_video.video_url} controls/>*/}
                    <Paragraph>{data.extra_description}</Paragraph>
                    <PriceWrapper>
                        <Paragraph style={{margin: '15px 0'}} strong>{!data.purchased ? `${data.price} EUR` : <Tag color={'green'}>{translator[lang].purchased}</Tag>}</Paragraph>
                        <Button
                            type={data.purchased ? 'default' : 'primary'}
                            danger={!data.purchased}
                            shape={'round'}
                            block
                            loading={buyLoading}
                            onClick={!data.purchased ? this.buy : () => this.props.history.push(`/course/${data.path}`)}
                        >{data.purchased ? translator[lang].viewCourse : translator[lang].buy}</Button>
                    </PriceWrapper>
                </Drawer>
            </div>
        )
    }

}

export default withRouter(SingleCourse);