import React, {Component} from 'react';
import Header from "./Header";
import styled from 'styled-components';
import Navigation from "./Navigation";
import {withRouter} from "react-router-dom";
import requests from "../requests";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #f3f3f3;
    //padding-top: 76px;
    
    ${props => props.direction === 'ltr' ? 'padding-left: 250px;' : 'padding-right: 250px;'}
    // padding-bottom: ${props => props.paddingBottom}px;
    direction: ${props => props.direction};
    @media screen and (max-width: 767px) {
        //padding-top: 72px;
      ${props => props.direction === 'ltr' ? 'padding-left: 80px;' : 'padding-right: 80px;'}
    }
`;

const Container = styled.div`
    padding: 15px;
`;

const Footer = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #000;
  color: #c9c9c9;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
`;


class Layout extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            path: window.location.pathname,
            // paddingBottom: 105
        }
    }

    // componentDidMount() {
    //     const height = document.getElementById('footer').offsetHeight;
    //     setTimeout(() => {
    //         this.setState({
    //             paddingBottom: height
    //         })
    //     }, 500);
    // }

    push = (path) => {
        this.props.history.push(path)
        this.setState({
            path: path
        })
        const {setTitle} = this.props;
        let title = null;
        switch (path) {
            case '/':
                title = 'My Courses';
                break;
            case '/buy':
                title = 'Buy Courses';
                break;
            case '/profile':
                title = 'Profile';
                break;
            default:
                title = null;
        }
        setTitle(title);
    }

    logout = async () => {
        this.setState({
            buttonLoad: true
        })
        await requests.post('/authorization/logout');
        localStorage.clear();
        window.location.href = '/';
        window.parent.postMessage(false, '*');
    }

    render () {
        const {children, brand, title, lang} = this.props;
        const {path, paddingBottom} = this.state;
        return <Wrapper paddingBottom={paddingBottom} direction={lang === 'ar' ? 'rtl' : 'ltr'}>
            {/*<Header title={title} url={brand.website} brand={brand.logo} logout={this.logout}/>*/}
            <Navigation lang={lang} path={path} push={this.push} logout={this.logout}/>
            <Container>
                {children}
            </Container>
            {/*<Footer id={'footer'}>{brand.footer}</Footer>*/}
        </Wrapper>
    }


}

export default withRouter(Layout);