import React, { useState, useRef } from 'react';
import { Modal, Form, Button, message, Input, DatePicker } from 'antd';
import requests from '../requests';

const RegistrationAgreementModal = (props) => {
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const handleSubmit = async ({ id: passport, date }) => {
    setLoading(true);
    const canvas = canvasRef.current;
    const signature = canvas.toDataURL('image/png');

    const { setUserKey, authorize } = props;
    setUserKey('pop', true);
    authorize();

    const res = await requests.post('/authorization/register_agree', {
      signature,
      passport,
      date,
    });
    if (res.status === 200) {
      message.success(res.data.message);
    } else {
      console.log(res);
      message.error(res.error);
    }
    setLoading(false);
  };

  // Common function to get coordinates from mouse or touch events
  const getCoordinates = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    return { x, y };
  };

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { x, y } = getCoordinates(e);

    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { x, y } = getCoordinates(e);

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  // Add touch event handlers
  const touchStart = (e) => {
    e.preventDefault();
    startDrawing(e.touches[0]);
  };

  const touchMove = (e) => {
    e.preventDefault();
    draw(e.touches[0]);
  };

  const touchEnd = (e) => {
    e.preventDefault();
    stopDrawing();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const validateCanvas = (_, value) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const canvasData = context.getImageData(
      0,
      0,
      canvas.width,
      canvas.height,
    ).data;

    for (let i = 0; i < canvasData.length; i += 4) {
      if (canvasData[i + 3] !== 0) {
        return Promise.resolve();
      }
    }

    return Promise.reject(new Error('Please provide a signature'));
  };

  const { user, brand } = props;
  const name = `${user.first_name} ${user.last_name}`;

  return (
    <div>
      <Modal
        title="ACADEMY ONLINE COURSES PURCHASE AGREEMENT"
        visible={true}
        closable={false}
        footer={null}
        confirmLoading={loading}
      >
        <Form onFinish={handleSubmit}>
          <p>
            I, {name},
            <Form.Item
              label="Passport or National ID #"
              name="id"
              rules={[
                {
                  required: true,
                  message: 'Please enter Passport or National ID',
                },
              ]}
            >
              <Input name="id" />
            </Form.Item>
            hereby acknowledge the following:
          </p>
          <ol type="1">
            <li>
              {brand.name} (educational academy operating via the website
              {brand.website}) is not affiliated with any 3rd party platforms or
              companies such as Airbnb, Amazon, Craigslist, gaming/gambling
              sites or investment sites such as Forex, CFDs or Options. Further,
              we do not accept payments for any 3rd parties such as but not
              limited to the examples stated above. If you are asked to utilize
              our academy to purchase online courses directed by any 3rd party,
              STOP. DO NOT UTILIZE OUR SERVICES. The company and/or platform
              that has directed you to us is probably fraudulent and
              perpetuating a SCAM against you.
            </li>
            <li>
              I understand that all funding activities have been undertaken by
              my own direction and control. I further acknowledge that I have
              not allowed any 3rd party to take control of my screen/computer
              through Remote Desktop software such as TeamViewer. I understand
              that allowing this type of remote access by a 3rd party is a sign
              that a fraud is being perpetuated against myself.
            </li>
            <li>
              The transactions I make and/or will make to {brand.name} are
              performed and submitted by myself and I fully authorize
              {brand.name} to execute the funds for my sole benefit. All
              transactions are performed at my own risk.
            </li>
            <li>
              If you have remitted funds to us at the direction of any 3rd
              party, please log into your account and request a refund back to
              your original payment method.
            </li>
            <li>
              I acknowledge that the transactions I make and/or will make to the
              bank account(s) of {brand.name} are indisputable and irreversible
              after the purchased courses are accessed via my account.
            </li>
            <li>
              I understand that {brand.name} is an educational academy and that
              I am conducting business solely through my own established account
              with {brand.name}.
            </li>
          </ol>
          <Form.Item name="date" label="Date" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="signature"
            labelCol={{ span: 24 }}
            label="Signature"
            rules={[
              {
                validator: validateCanvas,
              },
            ]}
          >
            <canvas
              ref={canvasRef}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onTouchStart={touchStart}
              onTouchMove={touchMove}
              onTouchEnd={touchEnd}
              width="450"
              height="200"
              style={{
                border: '1px solid #000',
                width: '100%',
                touchAction: 'none',
              }}
            ></canvas>
          </Form.Item>
          <Button type="secondary" onClick={clearCanvas}>
            Clear Signature
          </Button>
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Document
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RegistrationAgreementModal;
