import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Spinner from "../base/Spinner";
import requests from "../requests";
import {Button, Card, Empty, Typography} from "antd";
import renderHTML from "react-render-html";
import {
    PrinterOutlined
} from '@ant-design/icons';
import translator from "../translator";


const {Paragraph, Text} = Typography;

class Wire extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            id: props.match.params.id,
            data: null,
            errorMessage: false,
            loading: true
        }
    }

    async componentDidMount() {
        const {id} = this.state;
        const r = await requests.get(`/finance/wire?id=${id}`);
        if (r.status === 200) {
            this.setState({
                data: r.data
            });
        } else {
            this.setState({
                errorMessage: r.error
            });
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const {loading, errorMessage, data} = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        if (errorMessage) return <Empty style={{maxWidth: 300, margin: '0 auto'}} description={errorMessage} />;
        return <Card title={translator[lang].wtDetails}>
            <Paragraph>Please, transfer sum in amount of <Text strong>{data.payment.amount} {data.payment.currency}</Text> to following requisites in order to purchase <Text strong>{data.payment.course.title}</Text> course:</Paragraph>
            <div id="details">
                {renderHTML(data.details)}
            </div>
            <div style={{textAlign: 'right'}}>
                <Button onClick={() => window.print()} type={'default'} icon={<PrinterOutlined />}>{translator[lang].print}</Button>
            </div>
        </Card>
    }
}

export default withRouter(Wire);