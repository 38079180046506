import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Spinner from "../base/Spinner";
import requests from "../requests";
import {Empty, Typography, Row, Col, Button} from "antd";
import ReactPlayer from "react-player";
import translator from "../translator";


const {Title, Paragraph} = Typography;


class Info extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            info: null,
            errorMessage: null,
            buttonLoading: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const r = await requests.get(`/courses/info?id=${id}`);
        if (r.status === 200) {
            this.setState({
                info: r.data,
                loading: false
            });
        } else {
            this.setState({
                errorMessage: r.error,
                loading: false
            });
        }
    }

    buy = async () => {
        this.setState({
            buttonLoading: true
        });
    }

    render() {
        const {loading, info, errorMessage, buttonLoading} = this.state;
        const {lang} = this.props;
        if (loading) return <Spinner/>;
        if (errorMessage) return <Empty style={{maxWidth: 300, margin: '0 auto'}} description={errorMessage} />;
        return (
            <Row>
                <Col xs={{
                    span: 24
                }} md={{
                    span: 16,
                    offset: 4
                }}>
                    <Title level={4}>{info.title}</Title>
                    <Paragraph>{info.short_description}</Paragraph>
                    <ReactPlayer style={{marginBottom: 20}} height={'auto'} width={'100%'} url={info.intro_video.video_url} controls/>
                    <Paragraph style={{whiteSpace: 'pre'}}>{info.description}</Paragraph>
                    <div style={{textAlign: 'center'}}>
                        <Paragraph strong>{info.price} EUR</Paragraph>
                        <Button onClick={this.buy} loading={buttonLoading} size={'large'} type={'primary'} shape={'round'}>{translator[lang].buyCourse}</Button>
                    </div>
                </Col>
            </Row>
        )
    }

}

export default withRouter(Info);