import moment from 'moment';

class User {
  constructor() {
    if (localStorage.getItem('user')) {
      this.data = JSON.parse(localStorage.getItem('user'));
    } else {
      this.data = false;
    }
  }

  set = (key, value) => {
    if (typeof key === 'string') {
      this.data.user[key] = value;
      localStorage.setItem('user', JSON.stringify(this.data));
    }
  };

  get = (key = false) => {
    if (!key) return this.data;
    try {
      return this.data[key];
    } catch (e) {
      return this.data;
    }
  };

  authorize = (data) => {
    const compareDate = moment('15.06.2023', 'DD.MM.YYYY'); // Users registered before this date need don't need to sign an agreement
    const registration_date = moment(
      data.user.registration_date,
      'DD.MM.YYYY HH:mm',
    );

    if (registration_date.isBefore(compareDate)) {
      data.user.pop = true;
    }

    const object = JSON.stringify(data);
    localStorage.setItem('user', object);
    window.parent.postMessage({ user: true }, '*');
    return JSON.parse(object);
  };
}

export default User;
