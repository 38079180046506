import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Card, Switch, Typography} from "antd";
import ReactPlayer from "react-player";
import styled from 'styled-components';
import translator from "../translator";


const {Paragraph} = Typography;

const Player = styled.div`
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 20px;
    
    .player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
`;

class Lesson extends Component {

    render() {
        const {lesson, completed, complete, lang} = this.props;
        return <Card
            style={{width: '100%'}}
            title={lesson.title}
            extra={[translator[lang].completed, ': ', <Switch defaultChecked={completed} onChange={complete} />]}
        >
            <Player>
                <ReactPlayer
                    className={'player'}
                    url={lesson.video.video_url}
                    controls
                    onContextMenu={e => e.preventDefault()}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />
            </Player>
            <Paragraph>{lesson.video.description}</Paragraph>
        </Card>
    }
}

export default withRouter(Lesson);